import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import Layout from '../../components/layouts'
import Gallery from "react-photo-gallery"

const FeaturedPhotoDisplay = () => {

  const data = useStaticQuery(graphql`
  query PrivatePhotosQuery {
    prismicPhotogroup(uid: {eq: "private-photography"}) {
      data {
        photos {
          photo {
            alt
            src: url
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
  `)
  const featuredphotos = data.prismicPhotogroup.data.photos

  function translatedimensions(ref1, ref2){
    var num = "1"
    if (ref1 > ref2){
      num = "4"
    }else{
      num = "3"
    }
    return{
      num
    }
  }

  const galleryphotos = []
  featuredphotos.forEach((item, i) => galleryphotos.push({
    src:item.photo.src,
    height:item.photo.dimensions.height,
    width:item.photo.dimensions.width
  }))

  const featuredphotoid = 0
  return(
    <div className="mb-5 featured__photos__gallery">
      <Gallery photos={galleryphotos} />
    </div>
  )
}


export const PrivatePhotography = ({  }) => {

  return (
    <Layout>
      <div className="mt-5">
        <div className="page__content">
          <div className="media__body__center mb-5">
            <h1 className="display__title">
              Portraiture
            </h1>
            <FeaturedPhotoDisplay />
          </div>
        </div>
      </div>
    </Layout>
  )

}

export default withPreview(PrivatePhotography)
